





import { Vue, Component } from "vue-property-decorator";
import InvoicesResource from "@/resources/invoices-resource";
import { InvoiceStatus } from "@/data/invoice";

@Component
export default class InvoiceRedirectView extends Vue {
  async created(): Promise<void> {
    const code = this.$route.params.code;
    await InvoicesResource.get(code).then(async res => {
      const invoice = new InvoiceStatus(res);
      if (invoice.isPaymentStarted) {
        this.$router.push({ name: "invoice-success", params: { code: code } });
      } else {
        await invoice.tryPay();
      }
    });
  }
}
